import React from "react";
import axios from "axios";
import { CountryDropdown } from "react-country-region-selector";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { Alert } from "@material-tailwind/react";
import { FaDownload } from "react-icons/fa";
function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="h-6 w-6"
    >
      <path
        fillRule="evenodd"
        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
        clipRule="evenodd"
      />
    </svg>
  );
}
const FormStep = ({ step, handleSteps, FormData, Error, ...rest }) => {
  // const [uploadNewInput, setUploadNewInput] = useState(null);

  // const handleUploadNewClick = () => {
  //   const inputElement = (
  //     <input
  //       className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
  //       type="file"
  //       id="passportFileForNew"
  //       name="passportFileForNew"
  //       onChange={handleSteps}
  //       accept=".pdf, .doc, .docx"
  //     />
  //   );
  //   setUploadNewInput(inputElement);
  // };

  const handleViewFile = async (path) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}serve/`,
        // `http://localhost:4000/serve/`,
        {
          params: {
            filepath: `${path}`,
          },
          responseType: "arraybuffer",
        }
      );
      let mimeType;
      if (path.endsWith(".pdf")) {
        mimeType = "application/pdf";
      } else if (path.endsWith(".jpeg") || path.endsWith(".jpg")) {
        mimeType = "image/jpeg";
      } else if (path.endsWith(".png")) {
        mimeType = "image/png";
      } else {
        console.error("Unsupported file type");
        return;
      }

      const blob = new Blob([response.data], { type: mimeType });
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error:", error.message);
    }
  };
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  return (
    <div>
      {step === 0 && (
        <div className="bg-[#f2f7ff] w-[100%] sm:w-[80%] mx-auto rounded-lg p-4">
          <h2 className="text-xl font-bold text-center text-myBlue py-4">
            Consultation Process Briefing
          </h2>
          <p className="text-lg p-4">
            We understand that your journey towards obtaining a work permit is a
            crucial step in achieving your goals.<br></br> To ensure a smooth
            and personalized experience, we've designed a comprehensive 5-step
            process tailored just for you.<br></br>Before we dive into the
            details, let us guide you through our seamless process:
            <br></br> <strong>1. Consultation Process Briefing:</strong> Get an
            overview of the entire process, from understanding your needs to
            obtaining your work permit.<br></br>{" "}
            <strong>2. Basic Information:</strong> Provide us with essential
            details to initiate your personalized consultation.
            <br></br> <strong>3. Verification and Consultation:</strong> A
            member of our team will connect with you to verify information and
            discuss proceedings and pricing.
            <br></br> <strong> 4. Additional Information:</strong> Share any
            additional details that can enhance your application.<br></br>
            <strong>5. Work Permit Status:</strong> Stay informed about your
            application status. as we guide you through the final steps.
            <br></br> Ready to Begin? By clicking "Agree and Continue," you
            acknowledge our commitment to delivering a seamless experience
            tailored to your needs. Our expert team is here to assist you at
            every stage, ensuring a successful and stress-free journey towards
            obtaining your work permit.<br></br>Have questions or need
            assistance? Contact our support team.
          </p>

          <div className="inline-flex items-center px-1">
            <label className="flex items-center relative p-3 rounded-full cursor-pointer ">
              <input
                type="checkbox"
                checked={
                  FormData.step1.termsAndConditions ||
                  rest?.viewData?.termsAndConditions
                }
                id="termsAndConditions"
                name="termsAndConditions"
                onChange={handleSteps}
                className="form-checkbox  bg-myBlue text-yellow-500 border-yellow-500 focus:ring-yellow-500 h-5 w-5"
              />
              <span className="mt-px px-2 font-light text-gray-700 cursor-pointer select-none">
                {" "}
                I Agree To The Terms And Conditions.
              </span>
            </label>
            {Error.termsAndConditions ? (
              <span className="text-red-500">This field is required</span>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
      {step === 1 && (
        <div className="bg-[#f2f7ff] w-[100%] sm:w-[80%] mx-auto p-4 rounded-lg">
          <h2 className="text-xl font-bold text-center text-myBlue py-4">
            Personal Information
          </h2>

          <div className="w-[100%] mx-auto">
            <fieldset>
              <legend>Basic Information</legend>
              <div className="mb-5 flex flex-col md:flex-row gap-2">
                <div className="w-full md:w-[50%] mb-2 ">
                  <label
                    htmlFor="firstName"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    onChange={handleSteps}
                    value={
                      !rest.viewData
                        ? FormData.step2.firstName
                        : rest.viewData?.basic_information?.firstName
                    }
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="First Name"
                    required
                  />
                  {Error.firstName && !Error.firstNameInvalid && (
                    <span className="text-red-500">This field is required</span>
                  )}
                  {Error.firstNameInvalid && !Error.firstName && (
                    <span className="text-red-500">the input is invalid</span>
                  )}
                  {Error.firstNameInvalid && Error.firstName && (
                    <span className="text-red-500">the input is invalid</span>
                  )}
                </div>
                <div className="w-full md:w-[50%] mb-2">
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={
                      !rest.viewData
                        ? FormData.step2.lastName
                        : rest.viewData?.basic_information?.lastName
                    }
                    onChange={handleSteps}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Last Name"
                    required
                  />
                  {Error.lastName && !Error.lastNameInvalid && (
                    <span className="text-red-500">This field is required</span>
                  )}
                  {Error.lastNameInvalid && !Error.lastName && (
                    <span className="text-red-500">the input is invalid</span>
                  )}
                  {Error.lastNameInvalid && Error.lastName && (
                    <span className="text-red-500">the input is invalid</span>
                  )}
                </div>
              </div>
              <div className="mb-5 flex flex-col md:flex-row gap-2">
                <div className="w-full md:w-[50%] mb-2">
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Date Of Birth
                  </label>
                  <input
                    type="date"
                    id="dob"
                    name="dob"
                    max={getCurrentDate()}
                    value={
                      !rest.viewData
                        ? FormData.step2.dob
                        : rest.viewData?.basic_information?.dob
                    }
                    onChange={handleSteps}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Date of Birth"
                    required
                  />
                  {Error.dob ? (
                    <span className="text-red-500">This field is required</span>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="w-full md:w-[50%] mb-2">
                  <label
                    htmlFor="nationality"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Nationality
                  </label>
                  <CountryDropdown
                    id="nationality"
                    name="nationality"
                    value={
                      !rest.viewData
                        ? FormData.step2.nationality
                        : rest.viewData?.basic_information?.nationality
                    }
                    onChange={rest.handleNationality}
                    classes="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                  {Error.nationality ? (
                    <span className="text-red-500">This field is required</span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="mb-5 flex flex-col md:flex-row gap-2">
                <div className="w-full md:w-[49.5%] mb-2 ">
                  <label
                    htmlFor="Marital Status"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Marital Status
                  </label>
                  <select
                    id="maritalStatus"
                    name="maritalStatus"
                    onChange={handleSteps}
                    value={
                      !rest.viewData
                        ? FormData.step2.maritalStatus
                        : rest.viewData?.basic_information?.maritalStatus.toLowerCase()
                    }
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Marital Status"
                    required
                  >
                    <option value="">-Select-</option>
                    <option value="single">Single</option>
                    <option value="married">Married</option>
                    <option value="divorced">Divorced</option>
                    <option value="widowed">Widowed</option>
                    <option value="separated">Separated</option>
                    <option value="others">Others</option>
                  </select>
                  {Error.maritalStatus && (
                    <span className="text-red-500">This field is required</span>
                  )}
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>Contact Information</legend>
              <div className="mb-5 flex flex-col md:flex-row gap-2">
                <div className="w-full md:w-[50%] mb-2">
                  <label
                    htmlFor="currentCountry"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Country of Residence
                  </label>

                  <CountryDropdown
                    value={
                      !rest.viewData
                        ? FormData.step2.currentCountry
                        : rest.viewData?.basic_information?.currentCountry
                    }
                    id="currentCountry"
                    name="currentCountry"
                    onChange={rest.handleCountry}
                    classes="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                  {Error.currentCountry ? (
                    <span className="text-red-500">This field is required</span>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="w-full md:w-[50%] mb-2">
                  <label
                    htmlFor="currentAddress"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Full Address
                  </label>
                  <input
                    type="text"
                    id="currentAddress"
                    name="currentAddress"
                    onChange={handleSteps}
                    value={
                      !rest.viewData
                        ? FormData.step2.currentAddress
                        : rest.viewData?.basic_information?.currentAddress
                    }
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Current Full Address"
                    required
                  />
                  {Error.currentAddress ? (
                    <span className="text-red-500">This field is required</span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="mb-5 flex flex-col md:flex-row gap-2">
                <div className="w-full md:w-[50%] mb-2">
                  <label
                    htmlFor="currentPhone"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Phone Number
                  </label>
                  <PhoneInput
                    id="currentPhone"
                    name="currentPhone"
                    containerStyle={{
                      display: "flex",
                      backgroundColor: "rgb(250, 250, 250)",
                      borderRadius: "0.5rem",
                    }}
                    inputStyle={{
                      width: "100%",
                      opacity: 1,
                      backgroundColor: "rgb(250, 250, 250)",
                      border: "1px solid #d1d5db",
                      borderRadiusLeft: "0.5rem",
                      padding: "0.85rem",
                    }}
                    countryCodeEditable={true}
                    placeholder="Your Contact Number"
                    onChange={rest.handlePhoneNumberChange}
                    // value={phoneNumber}
                    value={
                      !rest.viewData
                        ? FormData.step2.currentPhone
                        : rest.viewData?.basic_information?.currentPhone.toString()
                    }
                    required
                  />
                  {Error.currentPhone ? (
                    <span className="text-red-500">This field is required</span>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="w-full md:w-[50%] mb-2 ">
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={
                      !rest.viewData
                        ? FormData.step2.email
                        : rest.viewData?.basic_information?.email
                    }
                    onChange={handleSteps}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Email Address"
                    required
                  />
                  {Error.email && !Error.emailInvalid && (
                    <span className="text-red-500">This field is required</span>
                  )}
                  {Error.emailInvalid && !Error.email && (
                    <span className="text-red-500">the input is invalid</span>
                  )}
                  {Error.emailInvalid && Error.email && (
                    <span className="text-red-500">the input is invalid</span>
                  )}
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>Case Information</legend>
              <div className="mb-5 flex flex-col md:flex-row gap-2">
                <div className="w-full md:w-[50%] mb-2">
                  <label
                    htmlFor="requestType"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Request Type
                  </label>
                  <select
                    id="requestType"
                    name="requestType"
                    onChange={handleSteps}
                    value={
                      !rest.viewData
                        ? FormData.step2.requestType
                        : rest.viewData?.basic_information?.requestType
                    }
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Marital Status"
                    required
                  >
                    <option value="">-Select-</option>
                    <option value="new">New</option>
                    <option value="extension">Extension</option>
                  </select>
                  {Error.requestType && (
                    <span className="text-red-500">This field is required</span>
                  )}
                </div>
                <div className="w-full md:w-[50%] mb-2 ">
                  <label
                    htmlFor="permitDuration"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Permit Duration
                  </label>
                  <select
                    id="permitDuration"
                    name="permitDuration"
                    onChange={handleSteps}
                    value={
                      !rest.viewData
                        ? FormData?.step2?.permitDuration
                        : rest.viewData?.basic_information?.permitDuration?.toLowerCase()
                    }
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Permit Duration"
                    required
                  >
                    <option value="">-Select-</option>
                    <option value="01 month">01 Month</option>
                    <option value="03 months">03 Months</option>
                    <option value="06 months">06 Months</option>
                    <option value="09 months">09 Months</option>
                    <option value="12 months">12 Months</option>
                  </select>
                  {Error?.permitDuration && (
                    <span className="text-red-500">This field is required</span>
                  )}
                </div>
              </div>
              <div className="mb-5 flex flex-col md:flex-row gap-2">
                <div className="w-full md:w-[49.5%] mb-2">
                  <label
                    htmlFor="caseDescription"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Purpose of Application
                  </label>
                  <textarea
                    id="caseDescription"
                    name="caseDescription"
                    onChange={handleSteps}
                    value={
                      !rest.viewData
                        ? FormData?.step2?.caseDescription
                        : rest.viewData?.basic_information?.caseDescription
                    }
                    rows={5}
                    cols={40}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Briefly explain the reason to apply"
                  />
                  {Error.caseDescription && (
                    <span className="text-red-500">This field is required</span>
                  )}
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="bg-[#f2f7ff] w-[100%] sm:w-[80%] mx-auto p-4 rounded-lg">
          <h2 className="text-xl font-bold text-center text-myBlue py-4">
            Verification and Consultation
          </h2>
          <p className="text-lg p-4">
            Great news! Your basic information has been successfully verified.
            <br />
            Now, it's time for a personalized consultation to delve deeper into
            your needs and aspirations. Here's what to expect in this step:
            <br />
            <strong>1: Verification Call:</strong> One of our experienced
            consultants will reach out to you for a brief verification call. We
            value your time, and this step is crucial in ensuring the accuracy
            of your information.
            <br />
            <strong>2: Detailed Discussion:</strong> During the call, we'll
            discuss your specific requirements, answer any questions you may
            have, and provide insights into the proceedings and pricing tailored
            to your situation.
            <br />
            <strong>3: Your Concerns, Our Priority:</strong> If you have any
            concerns or unique circumstances, this is the perfect opportunity to
            address them. Your satisfaction and confidence in the process are
            our top priorities.
            <br />
            <strong>4: Verification Token:</strong> After the above steps, you
            will receive an email containing your verification token to proceed
            with the application.
            <span className="block text-base mt-2 p-2 bg-white border-l-4 border-[#094b72] text-[#094b72] rounded">
              <strong>Note:</strong> Please check your spam or junk folder if
              you don't see the email in your inbox.
            </span>
          </p>

          <div className="w-full xl:w-[50%] mb-2  px-4">
            <label
              htmlFor="proceed"
              className="block mb-2 text-sm font-semibold text-gray-900 dark:text-white"
            >
              Enter Your Verification Token
            </label>
            <input
              type="text"
              id="proceed"
              name="proceed"
              onChange={handleSteps}
              value={
                !rest.viewData
                  ? FormData.step3.proceed
                  : rest.viewData.verificationToken
              }
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Provided Verification Token"
              required
            />
            {Error.proceed && !rest?.verificationError && (
              <span className="text-red-500">This field is required</span>
            )}
            {rest?.verificationError && (
              <span className="text-red-500">{rest?.verificationError}</span>
            )}
          </div>
        </div>
      )}
      {step === 3 &&
        (FormData.step2.requestType === "new" ||
          rest.viewData?.basic_information?.requestType === "new") && (
          <div className="bg-[#f2f7ff] w-[100%] sm:w-[80%] mx-auto rounded-lg p-4">
            <h2 className="text-xl font-bold text-center text-myBlue py-4">
              Additional Information
            </h2>
            <div className="w-[100%] mx-auto">
              <div className="mt-2 mb-5">
                <Alert
                  icon={<Icon />}
                  className="rounded border-l-4 border-[#17a2b8] bg-[#17a2b8]/10 font-medium text-[#17a2b8] "
                >
                  <ol
                    role="list"
                    className=" normal list-decimal text-sm sm:text-base"
                  >
                    Please download the Antecedents Certificate, fill it out,
                    and upload it in the 'Signed Antecedent Certificate'
                    section,Follow these steps:
                    <div className="pl-4">
                      <li>
                        Download the certificate from the provided "Download"
                        link.
                      </li>
                      <li>Fill out the required details.</li>
                      <li>Digitally sign the document.</li>
                      <li>
                        Return to this page and upload it in the 'Signed
                        Antecedent Certificate' section
                      </li>
                    </div>
                  </ol>
                  <a
                    href={
                      "https://workpermitconsultancy.com/Antecedent-Certificate7601.pdf"
                    }
                    className="inline-flex  bg-blue-100 text-black text-xs font-medium me-2  rounded dark:bg-blue-900 dark:text-blue-300 items-center px-3 py-1.5 mt-2  hover:outline-none hover:ring-2 hover:ring-[#17a2b8] hover:ring-offset-2"
                    download="Antecedent-Certificate7601.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaDownload className="mr-2 text-blue-800  download-icon" />
                    Download
                  </a>
                </Alert>
              </div>
              <div className="mb-5 flex flex-col lg:flex-row gap-2">
                <div className="w-full xl:w-[50%] mb-2 ">
                  <label
                    htmlFor="passportNumber"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Passport Number:
                  </label>
                  <input
                    type="text"
                    id="passportNumber"
                    name="passportNumber"
                    onChange={handleSteps}
                    value={
                      !rest.viewData
                        ? FormData.step4.passportNumber
                        : rest.viewData.additional_Information?.new
                            ?.passportNumber
                    }
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter Your Passport Number"
                    required
                  />
                  {Error.passportNumber && (
                    <span className="text-red-500">This field is required</span>
                  )}
                </div>
                <div className="w-full xl:w-[50%] mb-2 ">
                  <label
                    htmlFor="ibanNew"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    IBAN:
                  </label>
                  <input
                    type="text"
                    id="ibanNew"
                    name="ibanNew"
                    onChange={handleSteps}
                    value={
                      !rest.viewData
                        ? FormData.step4.ibanNew
                        : rest.viewData.additional_Information?.new?.ibanNew
                    }
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter Your IBAN"
                    required
                  />
                  {Error.ibanNew && (
                    <span className="text-red-500">This field is required</span>
                  )}
                </div>
              </div>
              <div className="mb-5 flex flex-col lg:flex-row gap-2">
                <div className="w-full xl:w-[50%] mb-2 flex flex-col">
                  <label htmlFor="passportFileForNew">Passport File</label>
                  {rest && !rest.viewData ? (
                    // Render input if rest.viewData does not exist

                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 file:mr-2 file:py-1 file:px-2
                      file:rounded-[8px] file:border-0
                      file:text-[13px] file:font-semibold
                      file:bg-[#dae5f6] file:text-myBlue"
                      type="file"
                      id="passportFileForNew"
                      name="passportFileForNew"
                      onChange={handleSteps}
                      accept=".pdf, .jpeg, .jpg, .png"
                    />
                  ) : //           {!rest.updateUser?.additional_Information?.new?.passportFileForNew?.path ? (
                  //             <input
                  // //               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 file:mr-2 file:py-1 file:px-2
                  //     file:rounded-[8px] file:border-0
                  //     file:text-[13px] file:font-semibold
                  //     file:bg-[#dae5f6] file:text-myBlue"
                  //               type="file"
                  //               id="passportFileForNew"
                  //               name="passportFileForNew"
                  //               onChange={handleSteps}
                  //               accept=".pdf, .doc, .docx"
                  //             />
                  //           ) : (
                  //             <div>
                  //   <a
                  //     onClick={(e) => {
                  //       e.preventDefault();
                  //       handleViewFile(
                  //         `${rest.updateUser?.additional_Information?.new?.passportFileForNew?.path}`
                  //       );
                  //     }}
                  //     target="_blank"
                  //     rel="noopener noreferrer"
                  //     className="text-blue-500 hover:underline cursor-pointer py-2"
                  //   >
                  //     View Passport File
                  //   </a>
                  //   <button
                  //     onClick={handleUploadNewClick}
                  //     className="text-blue-500 hover:underline cursor-pointer py-2"
                  //   >
                  //     Upload New
                  //   </button>
                  //   {uploadNewInput}
                  // </div>
                  //           )}
                  //         </div>
                  // Render anchor tag or message
                  rest.viewData?.additional_Information?.new?.passportFileForNew
                      ?.path ? (
                    <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          handleViewFile(
                            `${rest.viewData?.additional_Information?.new?.passportFileForNew?.path}`
                          );
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline cursor-pointer py-2"
                      >
                        View Passport File
                      </a>
                    </div>
                  ) : (
                    <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <div className="text-gray-500 text-sm mt-1">
                        No file uploaded
                      </div>
                    </div>
                  )}
                  {Error.passportFileForNew && (
                    <span className="text-red-500">This field is required</span>
                  )}
                  {rest.fileError?.passportFileForNew &&
                    rest.fileError?.passportFileForNew.trim().length > 0 &&
                    !Error.passportFileForNew && (
                      <span className="text-red-500">
                        {rest.fileError.passportFileForNew}
                      </span>
                    )}
                </div>
                <div className="w-full xl:w-[50%] mb-2 flex flex-col">
                  <label htmlFor="birthCertificate">
                    Attested Birth Certificate
                  </label>
                  {rest && !rest.viewData ? (
                    // Render input if rest.viewData does not exist
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 file:mr-2 file:py-1 file:px-2
                      file:rounded-[8px] file:border-0
                      file:text-[13px] file:font-semibold
                      file:bg-[#dae5f6] file:text-myBlue"
                      type="file"
                      id="birthCertificate"
                      name="birthCertificate"
                      onChange={handleSteps}
                      //value={FormData.step4.birthCertificate}
                      accept=".pdf, .jpeg, .jpg, .png"
                    />
                  ) : rest.viewData?.additional_Information?.new
                      ?.birthCertificate?.path ? (
                    <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          handleViewFile(
                            `${rest.viewData?.additional_Information?.new?.birthCertificate?.path}`
                          );
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline cursor-pointer py-2"
                      >
                        View Birth Certificate
                      </a>
                    </div>
                  ) : (
                    <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <div className="text-gray-500 text-sm mt-1">
                        No file uploaded
                      </div>
                    </div>
                  )}

                  {Error.birthCertificate && (
                    <span className="text-red-500">This field is required</span>
                  )}
                  {rest.fileError?.birthCertificate &&
                    rest.fileError?.birthCertificate.trim().length > 0 &&
                    !Error.birthCertificate && (
                      <span className="text-red-500">
                        {rest.fileError.birthCertificate}
                      </span>
                    )}
                </div>
              </div>
              <div className="mb-5 flex flex-col lg:flex-row gap-2">
                <div className="w-full xl:w-[50%] mb-2 flex flex-col">
                  <label htmlFor="birthCertificateOfPartnerOpt">
                    Birth Certificate of Partner(Optional)
                  </label>
                  {rest && !rest.viewData ? (
                    <>
                      <input
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 file:mr-2 file:py-1 file:px-2
                      file:rounded-[8px] file:border-0
                      file:text-[13px] file:font-semibold
                      file:bg-[#dae5f6] file:text-myBlue"
                        type="file"
                        id="birthCertificateOfPartnerOpt"
                        name="birthCertificateOfPartnerOpt"
                        onChange={handleSteps}
                        // value={FormData.step4.birthCertificateOfPartnerOpt}
                        accept=".pdf, .jpeg, .jpg, .png"
                      />
                      {rest.fileError?.birthCertificateOfPartnerOpt &&
                        rest.fileError?.birthCertificateOfPartnerOpt.trim()
                          .length > 0 && (
                          <span className="text-red-500">
                            {rest.fileError.birthCertificateOfPartnerOpt}
                          </span>
                        )}
                    </>
                  ) : rest.viewData?.additional_Information?.new
                      ?.birthCertificateOfPartnerOpt?.path ? (
                    <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          handleViewFile(
                            `${rest.viewData?.additional_Information?.new?.birthCertificateOfPartnerOpt?.path}`
                          );
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline cursor-pointer py-2"
                      >
                        View Birth Certificate Of Partner
                      </a>
                    </div>
                  ) : (
                    <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <div className="text-gray-500 text-sm mt-1">
                        No file uploaded
                      </div>
                    </div>
                  )}
                </div>
                <div className="w-full xl:w-[50%] mb-2 flex flex-col">
                  <label htmlFor="antecedentCertificateNew">
                    Signed Antecedents Certificate
                  </label>
                  {rest && !rest.viewData ? (
                    // Render input if rest.viewData does not exist
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 file:mr-2 file:py-1 file:px-2
                      file:rounded-[8px] file:border-0
                      file:text-[13px] file:font-semibold
                      file:bg-[#dae5f6] file:text-myBlue"
                      type="file"
                      id="antecedentCertificateNew"
                      name="antecedentCertificateNew"
                      onChange={handleSteps}
                      //value={FormData.step4.antecedentCertificateNew}
                      accept=".pdf, .jpeg, .jpg, .png"
                    />
                  ) : rest.viewData?.additional_Information?.new
                      ?.antecedentCertificateNew?.path ? (
                    <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          handleViewFile(
                            `${rest.viewData?.additional_Information?.new?.antecedentCertificateNew?.path}`
                          );
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline cursor-pointer py-2"
                      >
                        View Antecedents Certificate
                      </a>
                    </div>
                  ) : (
                    <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <div className="text-gray-500 text-sm mt-1">
                        No file uploaded
                      </div>
                    </div>
                  )}

                  {Error.antecedentCertificateNew && (
                    <span className="text-red-500">This field is required</span>
                  )}
                  {rest.fileError?.antecedentCertificateNew &&
                    rest.fileError?.antecedentCertificateNew.trim().length >
                      0 &&
                    !Error.antecedentCertificateNew && (
                      <span className="text-red-500">
                        {rest.fileError.antecedentCertificateNew}
                      </span>
                    )}
                </div>
              </div>
              <div className="mb-5 flex flex-col lg:flex-row gap-2">
                <div className="w-full xl:w-[50%] mb-2 flex flex-col">
                  <label htmlFor="marriageCertificateOpt">
                    Mariage Certificate(optional)
                  </label>
                  {rest && !rest.viewData ? (
                    <>
                      <input
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 file:mr-2 file:py-1 file:px-2
                      file:rounded-[8px] file:border-0
                      file:text-[13px] file:font-semibold
                      file:bg-[#dae5f6] file:text-myBlue"
                        type="file"
                        id="marriageCertificateOpt"
                        name="marriageCertificateOpt"
                        onChange={handleSteps}
                        // value={FormData.step4.marriageCertificateOpt}
                        accept=".pdf, .jpeg, .jpg, .png"
                      />
                      {rest.fileError?.marriageCertificateOpt &&
                        rest.fileError?.marriageCertificateOpt.trim().length >
                          0 && (
                          <span className="text-red-500">
                            {rest.fileError.marriageCertificateOpt}
                          </span>
                        )}
                    </>
                  ) : rest.viewData?.additional_Information?.new
                      ?.marriageCertificateOpt?.path ? (
                    <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          handleViewFile(
                            `${rest.viewData?.additional_Information?.new?.marriageCertificateOpt?.path}`
                          );
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline cursor-pointer py-2"
                      >
                        View Marriage Certificate
                      </a>
                    </div>
                  ) : (
                    <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <div className="text-gray-500 text-sm mt-1">
                        No file uploaded
                      </div>
                    </div>
                  )}
                </div>
                <div className="w-full xl:w-[50%] mb-2 flex flex-col ">
                  <label htmlFor="OtherDocuments">
                    Other Supporting Documents(optional)
                  </label>
                  {rest && !rest.viewData ? (
                    <>
                      <input
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 min-h-[90px] file:mr-2 file:py-1 file:px-2
                      file:rounded-[8px] file:border-0
                      file:text-[13px] file:font-semibold
                      file:bg-[#dae5f6] file:text-myBlue"
                        type="file"
                        id="OtherDocuments"
                        name="OtherDocuments"
                        onChange={handleSteps}
                        multiple
                        //value={(e) => e.target.file}
                        accept=".pdf, .jpeg, .jpg, .png"
                      />
                      {rest.fileError?.OtherDocuments &&
                        rest.fileError?.OtherDocuments.trim().length > 0 && (
                          <span className="text-red-500">
                            {rest.fileError.OtherDocuments}
                          </span>
                        )}
                    </>
                  ) : rest.viewData?.additional_Information?.new?.OtherDocuments
                      ?.length > 0 ? (
                    <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 min-h-[90px]">
                      {rest.viewData.additional_Information.new.OtherDocuments.map(
                        (document, index) => (
                          <React.Fragment key={index}>
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                handleViewFile(document.path);
                              }}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500 hover:underline cursor-pointer block"
                            >
                              View Document {index + 1}
                            </a>
                            {index !==
                            rest.viewData.additional_Information.new
                              .OtherDocuments.length -
                              1 ? (
                              <hr className="my-1" />
                            ) : null}
                          </React.Fragment>
                        )
                      )}
                    </div>
                  ) : (
                    <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 min-h-[90px]">
                      <div className="text-gray-500 text-sm mt-1">
                        No documents uploaded
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      {step === 3 &&
        (FormData.step2.requestType === "extension" ||
          rest.viewData?.basic_information?.requestType === "extension") && (
          <div className="bg-[#f2f7ff] w-[100%] sm:w-[80%] mx-auto rounded-lg p-4">
            <h2 className="text-xl font-bold text-center text-myBlue py-4">
              Additional Information
            </h2>
            <div className="w-[100%] mx-auto">
              <div className="mt-2 mb-5">
                <Alert
                  icon={<Icon />}
                  className="rounded border-l-4 border-[#17a2b8] bg-[#17a2b8]/10 font-medium text-[#17a2b8] "
                >
                  <ol
                    role="list"
                    className="normal list-decimal text-sm sm:text-base"
                  >
                    Please download the Antecedents Certificate, fill it out,
                    and upload it in the 'Signed Antecedent Certificate'
                    section,Follow these steps:
                    <div className="pl-4">
                      <li>
                        Download the certificate from the provided "Download"
                        link.
                      </li>
                      <li>Fill out the required details.</li>
                      <li>Digitally sign the document.</li>
                      <li>
                        Return to this page and upload it in the 'Signed
                        Antecedent Certificate' section
                      </li>
                    </div>
                  </ol>
                  <a
                    href={
                      "https://workpermitconsultancy.com/Antecedent-Certificate7601.pdf"
                    }
                    className="inline-flex  bg-blue-100 text-black text-xs font-medium me-2  rounded dark:bg-blue-900 dark:text-blue-300 items-center px-3 py-1.5 mt-2  hover:outline-none hover:ring-2 hover:ring-[#17a2b8] hover:ring-offset-2"
                    download="Antecedent-Certificate7601.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaDownload className="mr-2 text-blue-800  download-icon" />
                    Download
                  </a>
                </Alert>
              </div>
              <div className="mb-5 flex flex-col lg:flex-row gap-2">
                <div className="w-full xl:w-[50%] mb-2 ">
                  <label
                    htmlFor="currentNLPermitNumber"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    NL Residence Permit Number:
                  </label>

                  <input
                    type="text"
                    id="currentNLPermitNumber"
                    name="currentNLPermitNumber"
                    onChange={handleSteps}
                    value={
                      !rest.viewData
                        ? FormData.step4.currentNLPermitNumber
                        : rest.viewData.additional_Information?.extension
                            ?.currentNLPermitNumber
                    }
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter Your Current Permit Number"
                    required
                  />
                  {Error.currentNLPermitNumber && (
                    <span className="text-red-500">This field is required</span>
                  )}
                </div>
                <div className="w-full xl:w-[50%] mb-2 ">
                  <label
                    htmlFor="expiryNLPermitNumber"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Expiry Date NL Residence Permit:
                  </label>
                  <input
                    type="date"
                    id="expiryNLPermitNumber"
                    name="expiryNLPermitNumber"
                    onChange={handleSteps}
                    value={
                      !rest.viewData
                        ? FormData.step4.expiryNLPermitNumber
                        : rest.viewData.additional_Information?.extension
                            ?.expiryNLPermitNumber
                    }
                    className="bg-gray-50 border disablePast border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter Your Passport Number"
                    required
                  />
                  {Error.expiryNLPermitNumber && (
                    <span className="text-red-500">This field is required</span>
                  )}
                </div>
              </div>
              <div className="mb-5 flex flex-col lg:flex-row gap-2">
                <div className="w-full xl:w-[50%] mb-2 ">
                  <label
                    htmlFor="vNumber"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    V-Number:
                  </label>
                  <input
                    type="text"
                    id="vNumber"
                    name="vNumber"
                    onChange={handleSteps}
                    value={
                      !rest.viewData
                        ? FormData.step4.vNumber
                        : rest.viewData.additional_Information?.extension
                            ?.vNumber
                    }
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter V Number"
                    required
                  />
                  {Error.vNumber && (
                    <span className="text-red-500">This field is required</span>
                  )}
                </div>
                <div className="w-full xl:w-[50%] mb-2 ">
                  <label
                    htmlFor="bsnNumber"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    BSN Social Security Number:
                  </label>
                  <input
                    type="text"
                    id="bsnNumber"
                    name="bsnNumber"
                    onChange={handleSteps}
                    value={
                      !rest.viewData
                        ? FormData.step4.bsnNumber
                        : rest.viewData.additional_Information?.extension
                            ?.bsnNumber
                    }
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter BSN Social Security Number "
                    required
                  />
                  {Error.bsnNumber && (
                    <span className="text-red-500">This field is required</span>
                  )}
                </div>
              </div>
              <div className="mb-5 flex flex-col lg:flex-row gap-2">
                <div className="w-full xl:w-[50%] mb-2 ">
                  <label
                    htmlFor="passportNumberForExtension"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Passport Number:
                  </label>
                  <input
                    type="text"
                    id="passportNumberForExtension"
                    name="passportNumberForExtension"
                    onChange={handleSteps}
                    value={
                      !rest.viewData
                        ? FormData.step4.passportNumberForExtension
                        : rest.viewData.additional_Information?.extension
                            ?.passportNumberForExtension
                    }
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter Your Passport Number"
                    required
                  />
                  {Error.passportNumberForExtension && (
                    <span className="text-red-500">This field is required</span>
                  )}
                </div>
                <div className="w-full xl:w-[50%] mb-2 ">
                  <label
                    htmlFor="ibanExtension"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    IBAN:
                  </label>
                  <input
                    type="text"
                    id="ibanExtension"
                    name="ibanExtension"
                    onChange={handleSteps}
                    value={
                      !rest.viewData
                        ? FormData.step4.ibanExtension
                        : rest.viewData.additional_Information?.extension
                            ?.ibanExtension
                    }
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter Your IBAN"
                    required
                  />
                  {Error.ibanExtension && (
                    <span className="text-red-500">This field is required</span>
                  )}
                </div>
              </div>
              <div className="mb-5 flex flex-col lg:flex-row gap-2">
                <div className="w-full xl:w-[50%] mb-2 flex flex-col">
                  <label htmlFor="passportFileForExtension">
                    Passport File
                  </label>
                  {rest && !rest.viewData ? (
                    // Render input if rest.viewData does not exist
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500
                      file:mr-2 file:py-1 file:px-2
                      file:rounded-[8px] file:border-0
                      file:text-[13px] file:font-semibold
                      file:bg-[#dae5f6] file:text-myBlue"
                      type="file"
                      id="passportFileForExtension"
                      name="passportFileForExtension"
                      onChange={handleSteps}
                      //value={FormData.step4.passportFileForExtension}
                      accept=".pdf, .jpeg, .jpg, .png"
                    />
                  ) : // Render anchor tags or message
                  rest.viewData?.additional_Information?.extension
                      ?.passportFileForExtension ? (
                    <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          handleViewFile(
                            `${rest.viewData?.additional_Information?.extension?.passportFileForExtension?.path}`
                          );
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline cursor-pointer py-2"
                      >
                        View Passport File
                      </a>
                    </div>
                  ) : (
                    <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <div className="text-gray-500 text-sm mt-1">
                        No file uploaded
                      </div>
                    </div>
                  )}

                  {Error.passportFileForExtension && (
                    <span className="text-red-500">This field is required</span>
                  )}
                  {rest.fileError?.passportFileForExtension &&
                    rest.fileError?.passportFileForExtension.trim().length >
                      0 &&
                    !Error.passportFileForExtension && (
                      <span className="text-red-500">
                        {rest.fileError.passportFileForExtension}
                      </span>
                    )}
                </div>
                <div className="w-full xl:w-[50%] mb-2 flex flex-col">
                  <label htmlFor="antecedentCertificateExtension">
                    Signed Antecedents Certificate
                  </label>
                  {rest && !rest.viewData ? (
                    // Render input if rest.viewData does not exist
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 file:mr-2 file:py-1 file:px-2
                      file:rounded-[8px] file:border-0
                      file:text-[13px] file:font-semibold
                      file:bg-[#dae5f6] file:text-myBlue"
                      type="file"
                      id="antecedentCertificateExtension"
                      name="antecedentCertificateExtension"
                      onChange={handleSteps}
                      //value={FormData.step4.antecedentCertificateExtension}
                      accept=".pdf, .jpeg, .jpg, .png"
                    />
                  ) : rest.viewData?.additional_Information?.extension
                      ?.antecedentCertificateExtension?.path ? (
                    <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          handleViewFile(
                            `${rest.viewData?.additional_Information?.extension?.antecedentCertificateExtension?.path}`
                          );
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline cursor-pointer py-2"
                      >
                        View Antecedents Certificate
                      </a>
                    </div>
                  ) : (
                    <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <div className="text-gray-500 text-sm mt-1">
                        No file uploaded
                      </div>
                    </div>
                  )}

                  {Error.antecedentCertificateExtension && (
                    <span className="text-red-500">This field is required</span>
                  )}
                  {rest.fileError?.antecedentCertificateExtension &&
                    rest.fileError?.antecedentCertificateExtension.trim()
                      .length > 0 &&
                    !Error.antecedentCertificateExtension && (
                      <span className="text-red-500">
                        {rest.fileError.antecedentCertificateExtension}
                      </span>
                    )}
                </div>
              </div>
              <div className="mb-5 flex flex-col lg:flex-row gap-2">
                <div className="w-full xl:w-[50%] mb-2 flex flex-col mx-auto">
                  <label htmlFor="OtherDocumentsForExtension">
                    Other Supporting Documents(optional)
                  </label>
                  {rest && !rest.viewData ? (
                    <>
                      <input
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 min-h-[90px] file:mr-2 file:py-1 file:px-2
                      file:rounded-[8px] file:border-0
                      file:text-[13px] file:font-semibold
                      file:bg-[#dae5f6] file:text-myBlue"
                        type="file"
                        id="OtherDocumentsForExtension"
                        name="OtherDocumentsForExtension"
                        onChange={handleSteps}
                        multiple
                        accept=".pdf, .jpeg, .jpg, .png"
                      />
                      {rest.fileError?.OtherDocumentsForExtension &&
                        rest.fileError?.OtherDocumentsForExtension.trim()
                          .length > 0 && (
                          <span className="text-red-500">
                            {rest.fileError.OtherDocumentsForExtension}
                          </span>
                        )}
                    </>
                  ) : rest.viewData?.additional_Information?.extension
                      ?.OtherDocumentsForExtension?.length ? (
                    <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 min-h-[90px]">
                      {rest.viewData.additional_Information.extension.OtherDocumentsForExtension.map(
                        (path, index) => (
                          <React.Fragment key={index}>
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                handleViewFile(path.path);
                              }}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500 hover:underline cursor-pointer block"
                            >
                              View Document {index + 1}
                            </a>
                            {index !==
                              rest.viewData.additional_Information.extension
                                .OtherDocumentsForExtension.length -
                                1 && <hr className="my-1 border-gray-300" />}
                          </React.Fragment>
                        )
                      )}
                    </div>
                  ) : (
                    <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 min-h-[90px]">
                      <div className="text-gray-500 text-sm mt-1">
                        No documents uploaded
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

      {step === 4 && (
        <div className="bg-[#f2f7ff] w-[100%] sm:w-[80%] mx-auto rounded-lg p-4">
          <h2 className="text-xl font-bold text-center text-myBlue py-4">
            Work Permit Status
          </h2>
          <p className="text-lg p-4">
            Congratulations on successfully navigating through the consultation
            and verification process.<br></br> We appreciate your collaboration
            and dedication.
            <br></br> Now, let's bring your journey to a close by keeping you
            informed about your work permit application status:<br></br>
            <strong>1:Real-time Updates:</strong> Stay in the loop with
            real-time updates on the progress of your application.<br></br>
            <strong>2:Notifications:</strong> Receive timely notifications
            regarding any additional actions required or when your work permit
            status changes.<br></br> <strong>3:Final Guidance:</strong> Our team
            is here to provide any final guidance or support you may need as we
            approach the conclusion of the application process.
          </p>
        </div>
      )}
    </div>
  );
};

export default FormStep;
